#show_page_body_container {
    @include horizontal-vertical-centering();
    flex-direction: column;

    section {
        margin-top: 2%;
        width: 90%;
        background-color: white;
        @include box-shadow($shadow-color);
    }

    .top_block {
        display: flex;

        .name {
            color: $brand-primary;

            strong {
                color: $brand-primary;
                font-weight: bold;
            }
        }

        .name_mobile_version {
            display: none;
        }
    }

    .book_photo {
        position: relative;

        img {
            min-width: 380px;
        }
    }

    .right_block {
        background: url('/assets/img/rafters.svg') no-repeat;
        background-position: top 0px right 0px;
        padding: 0 20px;

        .description {
            margin-top: 20px;
        }
    }

    .stack {
        font-weight: bold;
        margin-bottom: 20px;
    }

    .social_links i {
        background-color: $brand-primary;
        color: white;
        width: 50px;
        height: 50px;
        @include horizontal-vertical-centering();
        font-size: 40px;
    }

    .ratings {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;

        > div {
            margin-right: 20px;

            p {
                margin: 0;
            }
        }

        svg {
            cursor: default;
        }
    }

    q, cite {
        font-size: 20px;
    }

    footer {
        background-color: #060606;
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .promo {
            color: white;

            em {
                color: white;
                font-style: normal;
                font-size: 20px;
            }
        }

        .white_gen_logo {
            display: block;
            width: 50px;
            margin: 0 10px;
        }

        .white_acs_logo {
            width: 200px;
        }
    }

    .return_link {
        display: block;
        background-color: white;
        padding: 10px 15px;
        margin: 15px 0 3% 0;
        @include box-shadow($shadow-color);
    }

    .return_link:hover {
        color: $brand-primary;
    }

    /*---------- RESPONSIVE ----------*/

    @media all and(max-width: 992px){
        section {
            width: 95%;
        }
    }

    @media all and(max-width: 768px){
        section {
            margin: 0;
            width: 100%;
            box-shadow: none;
        }

        .top_block {
            flex-direction: column;
            text-align: center;

            .name_mobile_version {
                display: block;
            }

            .name_desktop_version {
                display: none;
            }
        }

        .social_links ul, .ratings {
            display: flex;
            justify-content: center;
        }

        .right_block {
            background-position: top 0px center;
        }
    }

    @media all and(max-width: 576px){
        .book_photo {
            img {
                min-width: 90%;
            }
        }
    
        footer {
            flex-direction: column;

            div {
                margin-bottom: 20px;
            }

            .promo {
                text-align: center;
            }
        }
    }
}