/**
BOOK MAIN SCSS
**/

@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import '../variables';
@import '../mixins';

@import '../../vendor/adminlte/bootstrap/css/bootstrap.min.css';
@import '../../vendor/adminlte/dist/css/AdminLTE.css';
@import '../../vendor/adminlte/dist/css/skins/skin-black-light.min.css';
@import '../../vendor/adminlte/plugins/pace/pace.min.css';
@import '../../vendor/backpack/pnotify/pnotify.custom.min.css';
@import '../../vendor/backpack/backpack.base.css';
@import '../../vendor/star-rating-svg/src/css/star-rating-svg.css';

@import 'partials/general';
@import 'partials/index';
@import 'partials/show';