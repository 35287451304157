#index_page_body_container {
    @include horizontal-vertical-centering();
    flex-direction: column;
    * {
        font-size: 14px;
        color: $text-book-color;
    }

    section {
        width: 70%;

        header {
            font-size: 20px;
            margin: 25px 0;
            text-align: center;
        }

        h3 {
            background-color: $text-book-color;
            padding: 15px 15px 8px 15px;
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
            @include box-shadow($shadow-color);
            
            p, strong, span {
                color: white;
            }

            p:last-child {
                text-align: right;
            }

            span {
                display: block;
            }
        }

        h3:hover {
            background-color: $brand-primary;
            transition: background-color 0.5s;
        }

        .students_box {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            .student_infos {
                width: 20%;
                display: flex;
                flex-direction: column;
                text-align: center;
                margin: 0 10px 10px 0;

                img {
                    width: 100%;
                }

                h4 {
                    color: $brand-primary;
                    font-weight: bold;
                }

                a {
                    display: block;
                    width: 100px;
                    margin: auto;
                    padding: 6px 0;
                    border: 1px solid $text-book-color;
                    font-weight: 600;
                }

                a:hover {
                    border: 1px solid $brand-primary;
                    color: $brand-primary;
                }
            }
        }
    }

    /*---------- RESPONSIVE ----------*/

    @media all and(max-width: 768px){
        section {
            width: 90%;

            .students_box {
                .student_infos {
                    width: 30%;
                }
            }
        }
    }

    @media all and(max-width: 576px){
        section {
            h3 {
                flex-direction: column;
                align-items: flex-start;          

                p:last-child {
                    text-align: left;
                }

                span {
                    display: inline;
                }
            }

            .students_box {
                .student_infos {
                    width: 100%;
                    margin: 0 auto 20px auto;
                }
            }
        }
    }
}