* {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: normal;
    color: $text-book-color;
}

html, body {
    height: 100%;
    margin: 0;
}

a {
    text-decoration: none;
}

li {
    list-style-type: none;
}

q:before, q:after {
    content: '';
}

.list_inline {
    display: flex;
    padding: 0;

    li {
        margin-right: 15px;
    }

    a {
        color: $brand-primary;
    }
}

header.top_page {
    width: 100%;
    background: url(assets/img/book-top-background.jpg) no-repeat center;
    @include horizontal-vertical-centering();

    .black_acs_logo {
        display: block;
        width: 300px;
        margin: 40px 0;
    }
}